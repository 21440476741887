import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  HomeOutlined,
  LoadingOutlined,
  UserAddOutlined,
} from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Avatar, Button, Col, Divider, List, Radio, Row, Switch, Tag } from "antd"
import { currentStageTasks, updateStageTask } from "~/services/apis"
import { TASK_PRIORITY, TASK_STATUS_COLOR } from "~/constants"
import { escapeHtml, getAvatarBackgroundColor } from "~/utils"
import dayjs from "dayjs"
import { useAuth } from "~/hooks"

function EmployeePage() {
  const history = useHistory()
  const [selected, setSelected] = useState("overview")
  const [loading, setLoading] = useState(false)
  const [currentTasks, setCurrentTasks] = useState([])
  const { user, logout } = useAuth()

  const fetchCurrentTasks = async () => {
    try {
      setLoading(true)
      const response = await currentStageTasks()

      if (response?.currentStageTasks?.length > 0) {
        const sortedTasks = response.currentStageTasks.sort((a, b) => {
          // If a is not completed and b is completed, a comes first (-1)
          if (!a.completedAt && b.completedAt) return -1
          // If a is completed and b is not completed, b comes first (1)
          if (a.completedAt && !b.completedAt) return 1
          // If both are completed or both are not completed, maintain original order
          return 0
        })
        setCurrentTasks(sortedTasks)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCurrentTasks()
  }, [])

  const TaskList = ({ tasks, title }) => {
    const [selected, setSelected] = useState("allTasks")

    return (
      <div className="rounded-b-2xl border bg-white p-3 shadow">
        <div className="flex flex-row items-center justify-between">
          <div className="mb-3 text-xl font-semibold">{title}</div>
          <div>
            <Radio.Group
              block
              options={[
                {
                  label: "Công việc của tôi",
                  value: "myTasks",
                },
                {
                  label: "Tất cả",
                  value: "allTasks",
                },
              ]}
              defaultValue={selected}
              optionType="button"
              buttonStyle="solid"
              onChange={(e) => setSelected(e.target.value)}
            />
          </div>
        </div>
        <Divider className="bg-black" />
        <List
          itemLayout="horizontal"
          dataSource={tasks.filter((task) =>
            selected === "myTasks" ? task?.assignedToId === user?.id : true,
          )}
          locale={{
            emptyText: "Không có công việc nào hôm nay",
          }}
          loading={loading}
          renderItem={(item) => {
            const { completedAt, task } = item || {}
            const isCompleted = !!completedAt
            const completedLate = completedAt && dayjs().isAfter(completedAt, "day")
            const isProcessing = !completedAt && dayjs().isAfter(item?.startedAt)
            const isNotStarted = item?.startedAt && dayjs().isBefore(item?.startedAt)
            const isLate = isProcessing && dayjs().isAfter(dayjs(item?.startedAt), "day")

            const status = {
              [isCompleted]: TASK_STATUS_COLOR.completed,
              [completedLate]: TASK_STATUS_COLOR.completedLate,
              [isProcessing]: TASK_STATUS_COLOR.processing,
              [isNotStarted]: TASK_STATUS_COLOR.notStarted,
              [isLate]: TASK_STATUS_COLOR.late,
            }

            return (
              <Row key={item?.id} className={`${isCompleted ? "opacity-30" : ""}`}>
                <Col span={22}>
                  <List.Item onClick={() => history.push(`/employee/tasks/${item?.id}`)}>
                    <List.Item.Meta
                      title={item?.task?.name}
                      description={
                        <div className="flex flex-col gap-3">
                          <div className="flex flex-row items-center gap-3">
                            Quy trình canh tác:
                            <div className="font-semibold">
                              {item?.procedureStage?.procedure?.name}
                            </div>
                          </div>
                          <div className="flex flex-row items-center gap-3">
                            <span>Ngày bắt đầu</span>
                            <span className="font-semibold text-black">
                              {dayjs(item?.startedAt).format("HH:mm")} <ArrowRightOutlined />{" "}
                              {dayjs(item?.endedAt).format("HH:mm - DD/MM/YYYY")}
                            </span>
                          </div>
                          <div className="flex flex-row items-center gap-3">
                            Tình trạng:
                            <Tag color={status?.["true"]} className="text-[15px] font-medium">
                              {!isNotStarted &&
                                (isCompleted
                                  ? "Đã hoàn thành"
                                  : isLate
                                    ? "Chưa thực hiện"
                                    : "Đang thực hiện")}
                              {isNotStarted && (
                                <div className="text-gray-500">
                                  <span>Ngày bắt đầu thực hiện: </span>
                                  <span className="font-semibold">
                                    {dayjs(item?.startedAt).format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              )}
                            </Tag>
                          </div>

                          <div className="flex flex-row items-center gap-3">
                            Mức độ ưu tiên:
                            <Tag
                              color={
                                { common: "blue", high: "gold", important: "red" }[
                                  item?.task?.priority
                                ]
                              }
                              className="rounded-lg text-[10px]"
                            >
                              {TASK_PRIORITY?.[item?.task?.priority]}
                            </Tag>
                          </div>
                          <div className="flex flex-row items-center gap-3">
                            <div>Người phụ trách:</div>
                            <div>
                              {item?.assignedTo && (
                                <div className="flex items-center gap-[6px]">
                                  <Avatar
                                    style={{
                                      backgroundColor: getAvatarBackgroundColor(
                                        item?.assignedTo?.firstName?.[0],
                                      ),
                                    }}
                                    size={"small"}
                                  >
                                    {item?.assignedTo?.firstName?.[0] || ""}
                                  </Avatar>{" "}
                                  <div className="line-clamp-1 font-semibold">
                                    {item?.assignedTo?.firstName}
                                  </div>
                                </div>
                              )}
                              {!item?.assignedTo && (
                                <span className="font-semibold">Chưa có người phụ trách</span>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-row gap-3">
                            <div className="whitespace-nowrap">Ghi chú:</div>
                            <div className="line-clamp-3 font-semibold">
                              {escapeHtml(item?.note)}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                </Col>
                <Col span={2} className="mt-5">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={isCompleted}
                    onChange={(checked) => {
                      setCurrentTasks((prev) =>
                        prev.map((task) =>
                          task?.id === item?.id
                            ? { ...task, completedAt: checked ? dayjs().toISOString() : null }
                            : task,
                        ),
                      )
                      updateStageTask(item?.id, {
                        completedAt: checked ? dayjs().toISOString() : null,
                      })
                    }}
                  />
                </Col>
              </Row>
            )
          }}
        />
      </div>
    )
  }

  const canDo = currentTasks?.filter(
    (currentTask) => currentTask?.startedAt && dayjs(currentTask.startedAt).isBefore(dayjs()),
  )

  const others = currentTasks?.filter(
    (task) => !canDo.map((canDo) => canDo?.id)?.includes(task?.id),
  )

  return (
    <div className="relative mx-auto min-h-screen w-full max-w-lg bg-gray-100 shadow-lg">
      {loading && (
        <div className="absolute z-[20] grid h-full w-full place-items-center bg-black/60">
          <LoadingOutlined className="text-[48px] text-primary-color" />
        </div>
      )}
      <div className="relative z-[10]">
        <div className="sticky left-0 right-0 top-0 z-40 flex h-16 flex-row items-center justify-between bg-[#3a2b2a] px-10 shadow">
          <img
            src={"/assets/png/logo.png"}
            className="h-[50px] w-[50px] cursor-pointer"
            onClick={() => history.push("/employee/dashboard")}
          />
          {user?.role !== "employee" && (
            <a href={`/dashboard`}>
              <h1 className="inline-flex cursor-pointer items-center justify-center gap-1 text-[16px] font-medium text-white">
                <ArrowLeftOutlined /> Quay về Admin
              </h1>
            </a>
          )}
          {user?.role === "employee" && (
            <h1 className="text-[16px] font-medium text-white">Quản lý công việc</h1>
          )}
        </div>
        {selected === "overview" && (
          <>
            <TaskList tasks={canDo} title="Công việc hôm nay" />

            <div className="h-[64px]" />

            <TaskList tasks={others} title="Các công việc khác" />
          </>
        )}
        {selected === "account" && (
          <div className="rounded-b-2xl border bg-white p-3 shadow">
            <h2>
              {user?.firstName} {user?.lastName}
            </h2>

            <Button onClick={logout} block danger>
              Đăng xuất
            </Button>
          </div>
        )}

        <div className="h-[64px]" />
        <div className="z-100 fixed bottom-0 left-1/2 flex w-full max-w-lg -translate-x-1/2 transform flex-row items-center justify-around bg-white shadow-lg">
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault()
              setSelected("overview")
            }}
            className={`flex flex-col items-center justify-center whitespace-nowrap rounded-md px-2 py-2 font-semibold capitalize text-gray-400 ${selected === "overview" ? "!text-primary-color" : ""}`}
          >
            <HomeOutlined className="mb-[4px] text-[20px]" />
            <span>Trang chủ</span>
          </a>
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault()
              setSelected("account")
            }}
            className={`flex flex-col items-center justify-center whitespace-nowrap rounded-md px-2 py-2 font-semibold capitalize text-gray-400 ${selected === "account" ? "!text-primary-color" : ""}`}
          >
            <UserAddOutlined className="mb-[4px] text-[20px]" />
            <span>Tài khoản</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default EmployeePage
